import styled, { keyframes, css } from "styled-components";
import Img from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H0_MOBILE,
  H4,
  SUBTITLE_1,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 160px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface TypingEffectProps {
  slide?: number;
  width?: number;
}

export const moveLeftRightLoop = (width?: number) => keyframes`
  0% {
    left: -16px;
  };

  50% {
    left: calc(${width ? -64 - width : -64}px + 100vw);
  };

  100% {
    left: -16px;
  };
`;

export const TitleWrapper = styled.div`
  position: relative;
  height: 164px;
  padding: 0;
  margin: 0;
  margin-bottom: 48px;
`;

export const TitleScrollEffect = styled.div`
  ${H0_MOBILE}
  position: absolute;
  top: 0;
  left: -16px;
  padding: 0;
  margin: 0;
  color: #013cff;
  white-space: pre;
  overflow: hidden;
  animation: ${(props: TypingEffectProps) => moveLeftRightLoop(props.width)} 7s
    ease-in-out infinite;
  animation-fill-mode: forwards;
`;

export const DescriptionWrapper = styled.div`
  width: 90%;
`;

interface DescriptionProps {
  color?: string;
}

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin: 0;
  color: ${(props: DescriptionProps) => props.color || "#151515"};
  white-space: pre-line;
`;

export const PartnersPageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 88px 24px;
  display: flex;
  flex-direction: column;
  background: #013cff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface TeamMemberInfoWrapperProps {
  isSafari?: number;
}

export const TeamMemberInfoWrapper = styled.div`
  width: 100%;
  min-height: ${(props: TeamMemberInfoWrapperProps) =>
    props.isSafari ? "64vh" : "100%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.mobileS} {
    min-height: ${(props: TeamMemberInfoWrapperProps) =>
      props.isSafari ? "50vh" : "100%"};
  } ;
`;

export const ProfilePicture = styled(Img)`
  position: relative;
  width: 48vw;
  height: 48vw;
  margin: 32px 0;

  img {
    top: 0;
    right: 0;
    margin: 0 auto;
    width: auto !important;
    object-fit: cover !important;
    border-radius: 50%;
  }
`;

export const Column = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  ${H4}
  grid-column-end: 2 span;
  margin: 0;
  color: #ffffff;
`;

export const TeamMemberTitle = styled.h1`
  ${SUBTITLE_1}
  grid-column-end: 2 span;
  padding-bottom: 16px;
  margin: 0;
  color: #ffffff;
`;
