import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  TitleWrapper,
  TitleScrollEffect,
  DescriptionWrapper,
  Description,
  PartnersPageWrapper,
  TeamMemberInfoWrapper,
  Column,
  Title,
  ProfilePicture,
  TeamMemberTitle,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import {
  FluidImageNodesType,
  TeamMemberType,
} from "../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface ImagesType {
  members?: FluidImageNodesType;
}

interface TeamProps {
  pageChange: PageChange;
}

const TeamMobilePage: React.FC<TeamProps> = ({ pageChange }) => {
  const [textWidth, setTextWidth] = useState<number>();

  useEffect(() => {
    const handleWindowResize = () => {
      const elementWidth = document.getElementById("titleScroll")!.clientWidth;
      setTextWidth(elementWidth);
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const { t } = useTranslation("rnd");
  const data = useStaticQuery(imagesQuery) as ImagesType;

  return (
    <>
      <PageTitle subtitle={t(`our_team_title`)} />
      <PageWrapper animation={pageChange}>
        <TitleWrapper>
          <TitleScrollEffect id="titleScroll" width={textWidth}>
            Our team
          </TitleScrollEffect>
        </TitleWrapper>
        <Description
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(
              `${t(`team_description_firstParagraph`)}\n\n${t(
                `team_description_secondParagraph`
              )}`
            ),
          }}
        />
      </PageWrapper>

      <PartnersPageWrapper animation={pageChange}>
        <Title>{t(`team_partners_title`)}</Title>
        {(t(`team_partners`) as TeamMemberType[]).map(
          ({ name, description }, index) => {
            const profilePicture =
              data.members &&
              data.members.nodes.filter(
                img => img.name.toLowerCase() === name.toLowerCase()
              )[0];

            return (
              <TeamMemberInfoWrapper key={index} isSafari={isSafari ? 1 : 0}>
                {profilePicture && (
                  <ProfilePicture
                    fluid={profilePicture.childImageSharp.fluid}
                  />
                )}
                <Column>
                  <TeamMemberTitle>{name}</TeamMemberTitle>
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: fixConjunctions(description),
                    }}
                    color="#FFFFFF"
                  />
                </Column>
              </TeamMemberInfoWrapper>
            );
          }
        )}
      </PartnersPageWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    members: allFile(filter: { relativeDirectory: { eq: "team" } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default TeamMobilePage;
